.Reasons {
  padding: 0 2rem;
  display: flex;
  gap: 8rem;
}

.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
  max-width: 705px;
  padding-left: 2rem;
}

.left-r > img {
  object-fit: cover;
}

.left-r > :nth-child(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}

.left-r > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}

.left-r > :nth-child(3) {
  width: 14rem;
  grid-row: 2;
  grid-column: 2/3;
  max-height: 178px;
}

.left-r > :nth-child(4) {
  width: 10rem;
  grid-row: 2;
  grid-column: 3/4;
  max-height: 178px;
}

.right-r {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.right-r > span {
  font-weight: bold;
  color: var(--orange);
}

.right-r > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r > div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 1rem;
}

.details-r > div > img {
  height: 32px;
}

.partners {
  display: flex;
  gap: 1rem;
}

.partners > img {
  width: 2.5rem;
}

@media screen and (max-width: 1080px) {
  .Reasons {
    gap: 2rem;
  }
  .left-r {
    padding-left: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .left-r {
    display: flex;
    flex: none;
  }
  .left-r > :nth-child(1) {
    height: auto;
    width: 192px;
  }
  .left-r > :nth-child(2) {
    display: none;
  }
  .left-r > :nth-child(3) {
    display: none;
  }
  .left-r > :nth-child(4) {
    display: none;
  }
  .right-r > div {
    font-size: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .left-r > :nth-child(1) {
    display: none;
  }
}
